import React from "react";
import FbLogo from "../images/facebook.png";
import SpotifyLogo from "../images/spotify.png";
import EmailLogo from "../images/email.png";
import TelephoneLogo from "../images/telephone.png";
import { FooterStyle } from "../styled";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <FooterStyle>
      <div className="footer-line"></div>

      <a href="https://facebook.com/haapasound" target="_blank">
        <img className="some-icon" alt="Facebook Haapa Sound" src={FbLogo} />{" "}
      </a>
      <a href="https://open.spotify.com/playlist/1ktR9k9wQ08deLVS6VzPJp?si=RCovPqaWTdW1MPKOxzw5_Q" target="_blank">
        <img
          className="some-icon"
          alt="Spotify Haapa Sound"
          src={SpotifyLogo}
        />{" "}
      </a>
      Haapa Sound{" "}
      <a href="tel:+358503225108">
        <img
          className="some-icon"
          alt="Phone Haapa Sound"
          src={TelephoneLogo}
        />{" "}
      </a>
      <Link to="/yhteystiedot">
        <img className="some-icon" alt="Email Haapa Sound" src={EmailLogo} />
      </Link>
      <div style={{ marginBottom: "5px" }} />
    </FooterStyle>
  );
};

export default Footer;
