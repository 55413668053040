import { useState, useEffect, React } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import Header from "./components/Header";
import Mastering from "./components/Mastering";
import Footer from "./components/Footer";
import Frontpage from "./components/Frontpage";
import Contact from "./components/Contact";
import Order from "./components/Order";
import { PageBg } from "./styled";
import "./index.css";

const App = () => {
  const history = useHistory();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const loggedUserJSON = window.localStorage.getItem("loggedHaapaSoundUser");
    if (loggedUserJSON) {
      setUser(JSON.parse(loggedUserJSON));
    }
  }, []);


  const handleLogout = () => {
    history.push("/");
    window.localStorage.removeItem("loggedHaapaSoundUser");
    setUser(null);
  };

  return (
    <div style={{ height: "100%", minHeight: "100vh", background: "#0000" }}>
      <Header handleLogout={handleLogout} user={user} />
      <Switch>
        <Route path="/tilaus">
          <div className="article-page">
            <Order />
          </div>
        </Route>
        <Route path="/masterointi">
          <div className="article-page">
            <Mastering />
          </div>
        </Route>
        <Route path="/yhteystiedot">
          <div className="article-page">
            <Contact />
          </div>
        </Route>
        <PageBg>
          <Route path="/">
            <div className="article-page">
            <Frontpage />
            </div>
          </Route>
        </PageBg>
      </Switch>
      <Footer />
    </div>
  );
};

export default App;
