import { React } from "react";
import Itemline from "./Itemline";
import Formats from "./Formats";
import Total from "./Total";
// import Reset from "./Reset";

const Prices = ({ songPrices }) => {

  return (
    <>
      <br />
      <table>
        <tbody>
          <tr>
            <Itemline
              itemType={"LessThanFiveMin"}
              text={"Kappale alle 5 min"}
              price={songPrices.LessThanFiveMin}
            />
          </tr>
          <tr>
            <Itemline
              itemType={"FiveToTenMin"}
              text={"Kappale 5-10 min"}
              price={songPrices.FiveToTenMin}
            />
          </tr>
          <tr>
            <Itemline
              itemType={"TenToTwentyMin"}
              text={"Kappale 10-20 min"}
              price={songPrices.TenToTwentyMin}
            />
          </tr>
          <tr>
            <Itemline
              itemType={"OverTwentyMin"}
              text={"Kappale yli 20 min"}
              price={songPrices.OverTwentyMin}
            />
          </tr>
        </tbody>
      </table>
      <Formats />
      <br />
      <Total songPrices={songPrices} />
      {/* <Reset /> */}
      <br />
    </>
  );
};

export default Prices;
