const initialState = [
  { itemName: "cd", checked: false },
  { itemName: "mp3", checked: false },
  { itemName: "vinyl", checked: false },
  { itemName: "cassette", checked: false },
];

const formatReducer = (state = initialState, action) => {
  //   console.log("state now: ", state);
  //   console.log("action.data", action.data);
  switch (action.type) {
    case "CHANGE_SELECTION":
      return state.map((format) =>
        format.itemName !== action.data.itemName ? format : action.data
      );
    case "RESET_FORMATS":
      return initialState;
    default:
      return state;
  }
};

export const changeFormatSelection = (formatType, checked) => {
  return (dispatch) => {
    dispatch({
      type: "CHANGE_SELECTION",
      data: { itemName: formatType, checked: !checked },
    });
  };
};

export const resetFormats = () => {
  return (dispatch) => {
    dispatch({
      type: "RESET_FORMATS",
    });
  };
};

export default formatReducer;
