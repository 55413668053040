import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeFormatSelection } from "../reducers/formatReducer";
import "../index.css";

const Formats = () => {
  const dispatch = useDispatch();
  const formats = useSelector((state) => state.formats);
  const checkIfChecked = (formatType) => {
    const format = formats.filter((format) => format.itemName === formatType);
    return format[0].checked;
  };
  const updateSelection = (formatType) => {
    dispatch(changeFormatSelection(formatType, checkIfChecked(formatType)));
  };

  return (
    <div>
      <div className="formats">Julkaisuformaatit:</div>
      <table>
        <tbody>
          <tr>
            <td>
              <input
                type="checkbox"
                checked={true}
                value="stream"
                name="streaming"
                disabled="disabled"
              />{" "}
              striimaus
              {/* striimaus (16/24bit wav) */}
            </td>
            <td>
              <input
                type="checkbox"
                checked={checkIfChecked("cd")}
                value="cd"
                name="cd"
                onChange={() => updateSelection("cd")}
                className="checkbox"
              />{" "}
              CD
              {/* CD (DDP-kuva + player) */}
            </td>
            <td>
              <input
                type="checkbox"
                checked={checkIfChecked("vinyl")}
                value="vinyl"
                name="vinyl"
                onChange={() => updateSelection("vinyl")}
                className="checkbox"
              />{" "}
              vinyyli
              {/* Vinyyli (24-bit wav + cue sheet) */}
            </td>
            <td>
              <input
                type="checkbox"
                checked={checkIfChecked("cassette")}
                value="cassette"
                name="cassette"
                onChange={() => updateSelection("cassette")}
                className="checkbox"
              />{" "}
              kasetti
              {/* Kasetti (24-bit wav + cue sheet) */}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Formats;
