import React, { useEffect, useState } from "react";
import emailjs from "emailjs-com";
import { Row, Col, Form } from "react-bootstrap";
import { Button } from "../styled";

const OrderForm = (props) => {

  const intialState = {
    name: "",
    email: "",
    phone: "",
    message: "",
    url: "",
  };
  const [state, setState] = useState(intialState);
  const [checked, setChecked] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const handleInputChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  const handleOnSubmit = async (event) => {
    event.preventDefault();
    const formats = props.selectedFormat.map((format) => format.itemName).join(", ");
    try {
      const { name, email, phone, message, url } = state;
      if (name.trim() !== "" && email.trim() !== "" && message.trim() !== "") {
        setErrorMsg("");
        const emailParams = {
          formats: formats,
          total: props.total.toFixed(2),
          name: name,
          email: email,
          phone: phone,
          url: url,
          sample: checked,
          message: "Order to Haapa Sound: " + message,
        };
        await emailjs.send(
          "gmail",
          "tonitest",
          emailParams,
          process.env.REACT_APP_EMAILJS_USER
        );
        setErrorMsg("Kiitos, tilauksesi on lähetetty! Palaamme pian asiaan.");
        props.formSubmitted(true);
        setState(intialState);
        setChecked(false);
      } else {
        setErrorMsg("Lisää ainakin nimi, sähköposti ja viesti.");
      }
    } catch (error) {
      error.response && setErrorMsg(error.response.data);
    }
  };

  return (
    <React.Fragment>
      <Form className="search-form" onSubmit={handleOnSubmit}>
        <Row>
          <Col>
            <Form.Group controlId="name">
              <Form.Control
                type="text"
                name="name"
                value={state.name || ""}
                placeholder="Nimi"
                onChange={handleInputChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="email">
              <Form.Control
                type="text"
                name="email"
                value={state.email || ""}
                placeholder="Sähköposti"
                onChange={handleInputChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="phone">
              <Form.Control
                type="text"
                name="phone"
                value={state.phone || ""}
                placeholder="Puhelin"
                onChange={handleInputChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="message">
              <Form.Control
                as="textarea"
                rows={10}
                name="message"
                value={state.message || ""}
                placeholder="Viesti"
                onChange={handleInputChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="url">
              <p>Voit lähettää tiedostot esim. <a href='https://wetransfer.com' target="_blank">WeTransferin</a> avulla. Tiedostot tulee olla häviöttömässä muodossa (wav, flac tai aiff).
              </p><p>Lisää latauslinkki alla olevaan kenttään. Anna tarvittaessa oikeudet osoitteelle toni@haapasound.com.</p>
              <Form.Control
                type="text"
                name="url"
                value={state.url || ""}
                placeholder="Latauslinkki (Dropbox, Google Drive, WeTransfer tms.)"
                onChange={handleInputChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Check // prettier-ignore
              type="switch"
              id="custom-switch"
              label="Haluan maksuttoman näytteen (1-2 min)"
              checked={checked || false}
              value={checked || false}
              onChange={() => setChecked((state) => !state)}
            />
          </Col>
        </Row>
        {errorMsg && <p className="errorMsg">{errorMsg}</p>}
        <Button variant="info" type="submit">
          Lähetä
        </Button>
      </Form>
    </React.Fragment>
  );
};

export default OrderForm;
