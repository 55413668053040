import React from "react";
import { Link } from "react-router-dom";
import { Nav, Navbar } from "react-bootstrap";
import haapasound from "../images/haapasound.svg";
import Isologo from "../images/isologo.png";
import "../index.css";


const Header = ({ handleLogout, user }) => {

  const logocontainer = {
    display: "flex",
    flexDirection: "column",
    color: "#b2e6b2",
    fontSize: "14px",
    marginRight: "auto"
  };

  return (
    <div className="headercontainer">
      <div style={logocontainer}>
        <Link to="/">
          <img
            style={{ display: "flex", maxWidth: "95%" }}
            src={haapasound}
            alt="Haapa Sound"
          />
        </Link>
        <div style={{ marginLeft: "0px", color: "black" }}>
          Mastering Studio
        </div>
      </div>
      <Navbar collapseOnSelect expand="lg">
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav>
            <Nav.Link href="#" as="span">
              <Link className="color-nav" to="/">
                Etusivu
              </Link>
            </Nav.Link>
            <Nav.Link href="#" as="span">
              <Link className="color-nav" to="/masterointi">
                Masterointi
              </Link>
            </Nav.Link>
            <Nav.Link href="#" as="span">
              <Link className="color-nav" to="/tilaus">
                Tilaus
              </Link>
            </Nav.Link>

            <Nav.Link href="#" as="span">
              <Link className="color-nav" to="/yhteystiedot">
                Yhteystiedot
              </Link>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <a href="https://open.spotify.com/playlist/1ktR9k9wQ08deLVS6VzPJp?si=RCovPqaWTdW1MPKOxzw5_Q" target="_blank">
        <img className="FrontPage-logo" alt="Haapa Sound logo" src={Isologo} />
      </a>
    </div>
  );
};

export default Header;
