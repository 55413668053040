import axios from "axios";
import download from "downloadjs";

const baseUrl = "/api/orders";

const getAll = (token) => {
  const config = {
    headers: { Authorization: `bearer ${token}` },
  };
  const request = axios.get(baseUrl, config);
  return request.then((response) => response.data);
};

const create = async (formData, setProgress) => {
  const response = await axios.post(baseUrl, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (data) => {
      //Set the progress value to show the progress bar
      setProgress(Math.round((100 * data.loaded) / data.total));
    },
  });
  return response.data;
};

const downloadFile = async (id, path, mimetype) => {
  const result = await axios.get(baseUrl + "/" + id, {
    responseType: "blob",
  });
  const split = path.split("/");
  const filename = split[split.length - 1];
  return download(result.data, filename, mimetype);
};

// const update = (id, newObject) => {
//   const request = axios.put(`${baseUrl}/${id}`, newObject)
//   return request.then(response => response.data)
// }

// const remove = (id) => {
//   const config = {
//     headers: { Authorization: token },
//   }
//   const request = axios.delete(`${baseUrl}/${id}`, config)
//   return request.then(response => response.data)
// }

export default { getAll, create, downloadFile };
