import React from "react";
import QuantityButton from "./QuantityButton";

const Itemline = (props) => {
  return (
    <>
      <td>
        {props.text} (á {props.price} €)
      </td>
      <td></td>
      <td>
        <QuantityButton itemType={props.itemType} />
      </td>
    </>
  );
};

export default Itemline;
