const initialState = [
  { itemName: "LessThanFiveMin", amount: 0 },
  { itemName: "FiveToTenMin", amount: 0 },
  { itemName: "TenToTwentyMin", amount: 0 },
  { itemName: "OverTwentyMin", amount: 0 },
];

const itemReducer = (state = initialState, action) => {
  // console.log("state now: ", state);
  // console.log("action.data", action.data);
  switch (action.type) {
    case "KEEP_ZERO":
      return state;
    case "CHANGE_AMOUNT":
      return state.map((item) =>
        item.itemName !== action.data.itemName ? item : action.data
      );
    case "RESET_ITEMS":
      return initialState;
    case "KEEP_NINETYNINE":
      return state;
    default:
      return state;
  }
};

export const changePrice = (operation, item) => {
  if (operation === "decrease" && item.amount === 0) {
    return (dispatch) => {
      dispatch({
        type: "KEEP_ZERO",
      });
    };
  }
  if (operation === "increase" && item.amount === 149) {
    return (dispatch) => {
      dispatch({
        type: "KEEP_NINETYNINE",
      });
    };
  }
  if (operation === "decrease" && item.amount > 0) {
    return (dispatch) => {
      dispatch({
        type: "CHANGE_AMOUNT",
        data: { itemName: item.itemName, amount: item.amount - 1 },
      });
    };
  }
  if (operation === "increase") {
    return (dispatch) => {
      dispatch({
        type: "CHANGE_AMOUNT",
        data: { itemName: item.itemName, amount: item.amount + 1 },
      });
    };
  }
};

export const resetItems = () => {
  return (dispatch) => {
    dispatch({
      type: "RESET_ITEMS",
    });
  };
};

export default itemReducer;
