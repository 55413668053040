const initialState = [
  { total: 0 },
];

const totalReducer = (state = initialState, action) => {
  // console.log("state now: ", state);
  // console.log("action.data", action.data);
  switch (action.type) {
    case "CHANGE_TOTAL":
      return { total: action.data.total };
    default:
      return state;
  }
};

export const changeTotal = (total) => {
  return (dispatch) => {
    dispatch({
      type: "CHANGE_TOTAL",
      data: { total: total },
    });
  };
};

export default totalReducer;
