import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import itemReducer from "./reducers/itemReducer";
import formatReducer from "./reducers/formatReducer";
import orderReducer from "./reducers/orderReducer";
import totalReducer from "./reducers/totalReducer";

const reducer = combineReducers({
  items: itemReducer,
  formats: formatReducer,
  orders: orderReducer,
  totals: totalReducer
});

const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk)));

export default store;
