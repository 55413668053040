import { useState, React } from "react";
import emailjs from "emailjs-com";
import potikat from "../images/potikat.png";
import potikatLowRes from "../images/potikatLowRes.png";
import useProgressiveImg from "../useProgressiveImg";
import { Row, Col, Form } from "react-bootstrap";
import { Button } from "../styled";

const Contact = () => {
  const [error, setError] = useState();

  const [src, { blur }] = useProgressiveImg(potikatLowRes, potikat);

  const intialState = {
    name: "",
    email: "",
    message: "",
  };
  const [state, setState] = useState(intialState);

  const handleInputChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  const sendMessage = async (event) => {
    event.preventDefault();
    setError("");
    const { name, email, message } = state;
    const templateParams = {
      name: name,
      email: email,
      message: "Message to Haapa Sound: " + message,
    };
    emailjs
      .send(
        "gmail",
        "tonitest",
        templateParams,
        process.env.REACT_APP_EMAILJS_USER
      )
      .then(
        function (response) {
          setError("Viestisi on lähetetty! Otamme sinuun pian yhteyttä.");
          setState(intialState);
        },
        function (error) {
          setError(
            "Viestisi toimituksessa tapahtui virhe. Voit lähettää sähköpostia osoitteeseen haapasound@gmail.com."
          );
        }
      );
  };
  return (
    <div>
      <img
        src={src}
        style={{
          borderRadius: '12px',
          width: "100%",
          filter: blur ? "blur(20px)" : "none",
          transition: blur ? "none" : "filter 0.3s ease-out"
        }}
      />
      <br />
      <br />
      <h2>Haapa Sound</h2>
      <table>
        <tbody>
          <tr>
            <td>Y-tunnus:</td>
            <td>2889249-3</td>
          </tr>
          <tr>
            <td>Puh:</td>
            <td>
              <a href="tel:+358503225108">+358503225108</a>
            </td>
          </tr>
          <tr>
            <td>Email:</td>
            <td>
              <a href="mailto:toni@haapasound.com">toni@haapasound.com</a>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <h3 className="major">Lähetä viesti</h3>
      <div style={{
        backgroundColor: "#f9f5e9",
        paddingTop: "30px",
        paddingLeft: "15px",
        paddingRight: "15px",
      }}>
      <Form className="search-form" onSubmit={sendMessage}>
        {error && <p className="errorMsg">{error}</p>}
        <Row>
          <Col>
            <Form.Group controlId="name">
              <Form.Control
                type="text"
                name="name"
                value={state.name || ""}
                placeholder="Nimi"
                onChange={handleInputChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="name">
              <Form.Control
                type="text"
                name="email"
                value={state.email || ""}
                placeholder="Sähköposti"
                onChange={handleInputChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="name">
              <Form.Control
                as="textarea"
                rows={10}
                name="message"
                value={state.message || ""}
                placeholder="Viesti"
                onChange={handleInputChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Button variant="info" type="submit">
          Lähetä
        </Button>
      </Form>
      </div>
    </div>
  );
};

export default Contact;
