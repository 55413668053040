import React from "react";
import "../styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { changePrice } from "../reducers/itemReducer";

const QuantityButton = (props) => {
  const dispatch = useDispatch();
  const items = useSelector((state) => state.items);
  const item = items.filter((item) => item.itemName === props.itemType);
  const amount = item[0].amount;

  const updatePrice = (operation) => {
    dispatch(changePrice(operation, item[0]));
  };

  return (
    <div>
      <div className="quantity-input">
        <button
          className="quantity-input__modifier quantity-input__modifier--left"
          onClick={() => updatePrice("decrease", amount)}
        >
          {/* Minus sign: */}
          &mdash;
        </button>
        <input
          className="quantity-input__screen"
          type="text"
          value={amount}
          readOnly
        />
        <button
          className="quantity-input__modifier quantity-input__modifier--right"
          onClick={() => updatePrice("increase", amount)}
        >
          {/* Plus sign: */}
          &#xff0b;
        </button>
      </div>
    </div>
  );
};

export default QuantityButton;
