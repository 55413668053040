import React from "react";
import useProgressiveImg from "../useProgressiveImg";
import studioLowRes from "../images/studioLowRes.png";
import studio from "../images/studio.png";
import { Link } from "react-router-dom";

const Mastering = () => {

  const [src, { blur }] = useProgressiveImg(studioLowRes, studio);

  return (
    <>
      <img
        src={src}
        style={{
          borderRadius: '12px',
          width: "100%",
          filter: blur ? "blur(20px)" : "none",
          transition: blur ? "none" : "filter 0.3s ease-out"
        }}
      />
      <br />
      <br />
      <h2>Masterointi</h2>
      <p>
        Masterointistudio Haapa Sound sijaitsee Helsingin Roihupellossa.
        Korkealuokkaisilla työkaluilla, masterointiin suunnitellussa
        kuuntelutilassa äänityksistä erottuvat pienetkin yksityiskohdat, joita
        voidaan vielä hienosäätää.&nbsp;Masteroinnissa tarkistetaan kappaleiden
        sointia, tasataan voimakkuudet, asetetaan kappaleiden välit ja
        loppuhäivytykset, lisätään äänitteen metatiedot ja kootaan tiedostot eri
        julkaisuformaatteja (digi/CD/vinyyli/kasetti) varten.
      </p>
      <p>
        Olen Toni Haapanen, ja vastaan studion toiminnasta. Olen säveltänyt ja
        soittanut eri kokoonpanoissa sekä äänittänyt, miksannut ja masteroinut
        lukuisia projekteja 1990-luvun lopulta saakka. Eniten kokemusta minulla
        on indie-, proge-, metalli- ja konemusiikista, mutta puuhaan mielelläni
        muidenkin tyylien parissa. Olen suorittanut Sibelius-akatemian avoimessa
        taideyliopistossa äänityksen ja äänituotannon opintoja. Studiotyön
        ohella soitan edelleen aktiivisesti parissa eri bändissä (<a href="https://tuhosieni.org">Tuhosieni</a> ja <a href="https://alinenband.com">Alinen</a>).

        Etusivulla ja alla on esimerkkejä kappaleista, jotka olen masteroinut. {" "}
        <a href="https://www.discogs.com/artist/1171042-Toni-Haapanen">
          Discogsista
        </a>{" "}
        löytyy myös tietoja joistakin julkaisuista.
        <br />
        <br />
        <iframe style={{ borderRadius: '12px' }} src="https://open.spotify.com/embed/playlist/1ktR9k9wQ08deLVS6VzPJp?utm_source=generator" width="100%" height="652" frameBorder="0" allowFullScreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>

      </p>
      <Link to="/tilaus">Tilausta</Link> tehdessä tarvitaan seuraavat tiedot:
      <ul>
        <li>missä formaat(e)issa äänite aiotaan julkaista</li>
        <li>artistin/tekijän nimi</li>
        <li>lista kappaleiden nimistä ja järjestyksestä</li>
        <li>tarvittaessa albumin/EP:n nimi, levykoodi ja ISRC-koodit.&nbsp;</li>
        <li>mahdolliset erityistoiveet ja referenssibiisit.&nbsp;</li>
        <li>linkki tiedostoihin (esim. WeTransfer, Google Drive tms.).&nbsp;</li>
      </ul>
      <p>
        Masteroitavat tiedostot tulee olla häviöttömässä muodossa (wav / aiff /
        flac) ja niiden bittisyvyys (bit depth) ja näytteenottotaajuus (sample
        rate) mieluiten samat kuin miksatessakin. Yleensä kappale masteroidaan
        yhdestä stereoraidasta, mutta halutessasi voit lähettää esim.
        vokaaliraidan erikseen. Älä käytä master-kanavalla limitteriä raitoja
        viedessä. Tarkista myös, ettei tiedosto klippaa, eli äänenvoimakkuus
        pysyy korkeimmillaankin (true peak) muutaman dB:n nollan alapuolella.
        Muut master-kanavan efektit voi jättää päälle, etenkin jos ne ovat
        olennaisia kokonaisuuden kannalta, mutta vältä voimakasta
        kompressointia.
      </p>
      <p>
        Masterit toimitetaan tilaajalle wav-muodossa ja/tai DDP-levykuvana 1-2
        viikon kuluessa. Tarvittaessa masterit voidaan lähettää myös suoraan
        CD-painoon tai vinyylikaivertajalle.&nbsp;
      </p>
    </>
  );
};

export default Mastering;
