import React, { useEffect, useState } from "react";
import telefunken from "../images/telefunken.png";
import telefunkenLowRes from "../images/telefunkenLowRes.png";
import useProgressiveImg from "../useProgressiveImg";
import OrderForm from "./OrderForm";
import Prices from "./Prices";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { resetItems } from "../reducers/itemReducer";
import { resetFormats } from "../reducers/formatReducer";
import "../index.css";

const Order = () => {
  const formats = useSelector((state) => state.formats);
  const selectedFormat = formats.filter((format) => format.checked === true);
  const total = useSelector((state) => state.totals.total);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    setFormSubmitted(false);
    dispatch(resetItems());
    dispatch(resetFormats());
  }, []);

  const [src, { blur }] = useProgressiveImg(telefunkenLowRes, telefunken);
  const songPrices = {
    LessThanFiveMin: 59,
    FiveToTenMin: 69,
    TenToTwentyMin: 79,
    OverTwentyMin: 89,
  };

  if (formSubmitted == false) {
    return (
      <>
        <img
          src={src}
          style={{
            borderRadius: '12px',
            width: "100%",
            filter: blur ? "blur(20px)" : "none",
            transition: blur ? "none" : "filter 0.3s ease-out"
          }}
        />
        <br />
        <br />
        <h2>Tilaa masterointi</h2>
        Tee tilaus alla olevaa lomaketta käyttäen.
        Ilmoita viestikentässä artisti(e)n nimi, julkaisuformaatit, kappaleiden nimet ja
        järjestys sekä mahdolliset erityistoiveet masteroinnin suhteen. CD- ja vinyylijulkaisuihin tarvitaan myös levyn nimi, levykoodi
        ja ISRC-koodit.{" "}<Link to="/masterointi">Lisätietoja</Link>
        <br />
        <br />
        Voit myös tilata maksuttoman näytteen (1-2 minuuttia) yhdestä kappaleesta.
        <br />
        <br />
        Arvioitu toimitusaika on 1-2 viikkoa.
        <div>Yli 150 euron tilauksista alennus 10-25%.</div>
        <br />
        <div style={{
          backgroundColor: "#f9f5e9",
          paddingTop: "50px",
          paddingLeft: "15px",
          paddingRight: "15px",
        }}>
          <h5>Tilauslomake</h5>
          <Prices songPrices={songPrices} />
          <OrderForm selectedFormat={selectedFormat} total={total} formSubmitted={setFormSubmitted} />
        </div>
      </>
    );
  }
  else {
    return (
      <p className="errorMsg">Kiitos, tilauksesi on lähetetty! Palaamme pian asiaan.</p>
    )
  }
};

export default Order;
