import styled from "styled-components";
import aspen from "./images/background.jpg";

export const PageBg = styled.div`
  z-index: 4;
  width: 100%;
`;

export const Navigation = styled.div`
  background: #a3c8a3;
  padding: 1em;
`;

export const Button = styled.button`
  background: #eaf2ea;
  font-size: 1em;
  margin: 1em;
  padding: 0.25em 1em;
  border: 2px solid #4e664e;
  color: black;
  border-radius: 3px;
`;

export const Input = styled.input`
  margin: 0.25em;
  width: 200px;
`;
export const Textarea = styled.textarea`
  margin: 0.25em;
  width: 200px;
  height: 200px;
`;

export const Rows = styled.div`
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  margin-left: 1.25em;
  font-weight: bold;
`;

export const FooterStyle = styled.div`
  background-color: #F0EFD2;
  color: #696969;
  position: fixed;
  left: 0;
  bottom: 0;
  height: auto;
  width: 100%;
  z-index: 3;
  text-align: center;
  font: 0.8em "Apple System", sans-serif;
  /* box-shadow: 1px 1px 2px #696969, 0 0 3px green; */
`;

export const Table = styled.table`
  caption-side: top;
  border: none;
  border-collapse: collapse;

  caption-side: bottom;
  td,
  th {
    border: none;
  }

  td {
    padding: 5px 10px;
  }

  tbody tr {
    :nth-of-type(odd) {
      background-color: #efefef;
    }
    :hover {
      background-color: #759975;
    }
  }
  thead > tr {
    background-color: #c2c2c2;
  }
  caption {
    font-size: 0.9em;
    padding: 5px;
    font-weight: bold;
  }
`;

export const ImageContainer = styled.div`
  z-index: 5;

  margin-top: 16px;
  height: 300px;
`;

export const StyledImage = styled.div`
  z-index: 5;
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
`;
