import orderService from "../services/orders";

const orderReducer = (state = [], action) => {
  // console.log('state now: ', state)
  // console.log('action', action)
  switch (action.type) {
    case "NEW_ORDER":
      return [...state, action.data];
    case "INIT_ORDERS":
      return action.data;
    default:
      return state;
  }
};

export const createOrder = (order) => {
  const newObject = order;
  return async (dispatch) => {
    const newOrder = await orderService.create(newObject);
    dispatch({
      type: "NEW_ORDER",
      data: newOrder,
    });
  };
};

export const initializeOrders = (token) => {
  return async (dispatch) => {
    const orders = await orderService.getAll(token);
    dispatch({
      type: "INIT_ORDERS",
      data: orders,
    });
  };
};

export default orderReducer;
