import { React } from "react";
import toni from "../images/toni.png";
import toniLowRes from "../images/toniLowRes.png";
import useProgressiveImg from "../useProgressiveImg";
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'
import "../index.css";

const Frontpage = () => {
  const [src, { blur }] = useProgressiveImg(toniLowRes, toni);

  return (
    <div className="frontpage">
      <div className="image-and-text">
        <div className="FrontPage-text">
          Tervetuloa! Haapa Sound on vuonna 2017 perustettu masterointistudio Helsingin Roihupellossa.
          Olen Toni Haapanen, intohimoinen äänipeikko ja musiikintekijä itsekin.
          <br />
          <br />
          Masteroinnissa minulle tärkeintä on mahdollisimman hyvä lopputulos, joka kunnioittaa myös artistin omaa näkemystä.
          Tästä periaatteesta en halua tinkiä, vaikka se toisinaan vaatii paljonkin pikkutarkkaa työtä.
          Tarvittaessa tarjoan myös apua miksausten parantamiseen.
          <br />
          <br />
          Alla on esimerkkejä kappaleista, jotka olen masteroinut, ja lisää löytyy <a href="https://open.spotify.com/playlist/1ktR9k9wQ08deLVS6VzPJp?si=RCovPqaWTdW1MPKOxzw5_Q" target="_blank">
            soittolistalta
          </a>.
          {" "}
          Joistakin äänitteistä löytyy myös tietoja{" "}
          <a href="https://www.discogs.com/artist/1171042-Toni-Haapanen" target="_blank">
            Discogsista
          </a>.
          <br />
          </div>
          <div className="FrontPage-toni">
            <img
              src={src}
              alt="Toni Haapanen"
              style={{
                borderRadius: '50%',
                width: "85%",
                marginLeft: "50px",
                filter: blur ? "blur(20px)" : "none",
                transition: blur ? "none" : "filter 0.3s ease-out"
              }}
            />

        </div>
      </div>
      <div className="FrontPage-text">
        <br />
      </div>
      <div className="video-row">
        <div className="video-item">
          <LiteYouTubeEmbed
            id="d1GL4k0z_QU"
            title="Joose Keskitalo - En lähde surussa"
          />
        </div>
        <br />
        <div className="video-item">
          <LiteYouTubeEmbed
            id="Pqfk3SCOBaA"
            title="Ester Nuori Leppä - Kaikki alkoi taivaasta"
          />
        </div>
        <br />
        <div className="video-item">
          <LiteYouTubeEmbed
            id="ZZHn_LiGSBI"
            title="Hullu Ruusu - Sääskitulet"
          />
        </div>
        <br />
      </div>
      <div className="video-row">
        <div className="video-item">
          <LiteYouTubeEmbed
            id="MzCqqcjDMi4"
            title="Tiiu Helinä - Vähinä (Draama-Helmi Mix)"
          />
        </div>
        <br />
        <div className="video-item">
          <LiteYouTubeEmbed
            id="RqVrrFsIrjs"
            title="RuoTo - Kohtaaminen"
          />
        </div>
        <br />
      </div>
      <div className="video-row">
        <div className="video-item">
          <LiteYouTubeEmbed
            id="vgl_ozqmfAc"
            title="Joonas Hauveli - Siskoni Suomi"
          />
        </div>

        {" "}
        <br />
        <div className="video-item">
          <LiteYouTubeEmbed
            id="74p4fqIK99g"
            title="Metsäheikki - Metsäpoikia"
          />
        </div>
        <br />
        <div className="video-item">
          <LiteYouTubeEmbed
            id="2u692azaXME"
            title="Below The Graves - Death Is Always Hungry"
          />
        </div>
        <br />
      </div>
      <div className="video-row">
        <div className="video-item">
          <LiteYouTubeEmbed
            id="KMyL1cSb4Gc"
            title="Alinen - Ainut"
          />
        </div>
        {" "}
        <br />
        <div className="video-item">
          <LiteYouTubeEmbed
            id="0eF3ZcCix-0"
            title="Tuhosieni - Tunturi"
          />
        </div>
        <br />
      </div>
      <div className="video-row">
        <div className="video-item">
          <LiteYouTubeEmbed
            id="TbyuTVzD3ks"
            title="Ruuhi - Orja"
          />
        </div>
        {" "}
        <br />
        <div className="video-item">
          <LiteYouTubeEmbed
            id="7f1_51t9xRE"
            title="Konsta, Kusti & Faarao - Vara para"
          />
        </div>
        {" "}
        <br />
        <div className="video-item">
          <LiteYouTubeEmbed
            id="ssrWkbutkyY"
            title="Hulda Huima - Kotimatka"
          />
        </div>
      </div>
    </div>
  );
};

export default Frontpage;
